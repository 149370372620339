import { createApp, reactive } from 'vue';
import router from '@/plugins/router';

import App from '@/App.vue';

/* Global style */
import '@/assets/css/styles.css';

const app = createApp(App)
  .use(router);

app.config.globalProperties.settings = reactive({});

router.isReady().then(async () => {
  app.mount('#app');
});
