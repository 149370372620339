import Section from '@/components/Section.vue';

export default [
  {
    path: '/',
    redirect: { name: 'login' },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./views/Login.vue'),
  },
  {
    name: 'forgot-password',
    path: '/wachtwoord',
    component: () => import('./views/ForgotPassword.vue'),
  },
  {
    name: 'reset-password',
    path: '/wachtwoord/:token/reset',
    component: () => import('./views/ResetPassword.vue'),
    props: (route) => ({ token: route.params.token, email: route.query.email }),
  },
  {
    name: 'site',
    path: '/sites/:site',
    component: () => import('./views/Site.vue'),
    redirect: { name: 'site-dashboard' },
    props: (route) => ({ slug: route.params.site }),
    children: [
      {
        path: 'dashboard',
        name: 'site-dashboard',
        component: () => import('./views/Dashboard.vue'),
      },
      {
        path: 'paginas',
        name: 'site-pages',
        component: Section,
        redirect: { name: 'site-pages-overview' },
        children: [
          {
            path: '',
            name: 'site-pages-overview',
            component: () => import('./views/Pages.vue'),
          },
          {
            path: 'toevoegen',
            name: 'site-pages-add',
            component: () => import('./views/PagesAdd.vue'),
          },
          {
            path: ':id',
            name: 'site-page',
            props: (route) => ({ id: +route.params.id }),
            component: () => import('./views/PagesEdit.vue'),
          },
        ],
      },
      {
        path: 'nieuws',
        name: 'site-news',
        component: Section,
        redirect: { name: 'site-news-overview' },
        children: [
          {
            path: '',
            name: 'site-news-overview',
            component: () => import('./views/News.vue'),
          },
          {
            path: 'toevoegen',
            name: 'site-news-add',
            component: () => import('./views/NewsAdd.vue'),
          },
          {
            path: ':id',
            name: 'site-news-item',
            props: (route) => ({ id: +route.params.id }),
            component: () => import('./views/NewsEdit.vue'),
          },
        ],
      },
      {
        path: 'events',
        name: 'site-events',
        component: Section,
        redirect: { name: 'site-events-overview' },
        children: [
          {
            path: '',
            name: 'site-events-overview',
            component: () => import('./views/Events.vue'),
          },
          {
            path: 'toevoegen',
            name: 'site-events-add',
            component: () => import('./views/EventAdd.vue'),
          },
          {
            path: ':id',
            name: 'site-event',
            props: (route) => ({ id: +route.params.id }),
            component: () => import('./views/EventEdit.vue'),
          },
        ],
      },
      {
        path: 'leden',
        name: 'site-members',
        component: Section,
        redirect: { name: 'site-members-overview' },
        children: [
          {
            path: '',
            name: 'site-members-overview',
            component: () => import('./views/Members.vue'),
          },
          {
            path: 'toevoegen',
            name: 'site-member-add',
            component: () => import('./views/MemberAdd.vue'),
          },
          {
            path: ':id',
            name: 'site-member',
            props: (route) => ({ id: +route.params.id }),
            component: () => import('./views/MemberEdit.vue'),
          },
        ],
      },
      {
        path: 'instellingen',
        name: 'site-settings',
        component: () => import('./views/Settings.vue'),
      },
      {
        path: 'account',
        name: 'site-account',
        component: () => import('./views/Account.vue'),
      },
    ],
  },
  {
    name: 'admin',
    path: '/beheer',
    component: () => import('./views/Admin.vue'),
    redirect: { name: 'admin-dashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'admin-dashboard',
        component: () => import('./views/AdminDashboard.vue'),
      },
      {
        path: 'sites',
        name: 'admin-sites',
        component: Section,
        redirect: { name: 'admin-sites-overview' },
        children: [
          {
            path: '',
            name: 'admin-sites-overview',
            component: () => import('./views/Sites.vue'),
          },
          {
            path: 'toevoegen',
            name: 'admin-site-add',
            component: () => import('./views/SiteAdd.vue'),
          },
          {
            path: ':id',
            name: 'admin-site',
            props: (route) => ({ id: +route.params.id }),
            component: () => import('./views/SiteEdit.vue'),
          },
        ],
      },
      {
        path: 'gebruikers',
        name: 'admin-users',
        component: Section,
        redirect: { name: 'admin-users-overview' },
        children: [
          {
            path: '',
            name: 'admin-users-overview',
            component: () => import('./views/Users.vue'),
          },
          {
            path: 'toevoegen',
            name: 'admin-user-add',
            component: () => import('./views/UserAdd.vue'),
          },
          {
            path: ':id',
            name: 'admin-user',
            props: (route) => ({ id: +route.params.id }),
            component: () => import('./views/UserEdit.vue'),
          },
        ],
      },
      {
        path: 'import',
        name: 'admin-import',
        component: Section,
        redirect: { name: 'admin-import-overview' },
        children: [
          {
            path: '',
            name: 'admin-import-overview',
            component: () => import('./views/Import.vue'),
          },
        ],
      },
      {
        path: 'account',
        name: 'admin-account',
        component: () => import('./views/Account.vue'),
      },
    ],
  },
];
