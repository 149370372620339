<template>
  <router-view />
  <notification
    v-if="notification"
    v-bind="notification"
    @close="notification = false"
  />
  <div id="modal" />
</template>

<script>
import EventBus from '@/eventBus';
import Notification from '@/components/Notification.vue';

export default {
  components: { Notification },
  data() {
    return {
      notification: null,
    };
  },
  created() {
    EventBus.on('notification', (notification) => {
      this.notification = notification;
    });
  },
};
</script>
